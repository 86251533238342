module.exports = [{
      plugin: require('/usr/local/nginx/html/other_domains/thesoundstageatstrangeland/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js","omitGoogleFont":true},
    },{
      plugin: require('/usr/local/nginx/html/other_domains/thesoundstageatstrangeland/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":""},
    },{
      plugin: require('/usr/local/nginx/html/other_domains/thesoundstageatstrangeland/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
